$grayColorInactiveDots: #cdcdcd;
$orangeColor: #ff5e05;

#recommended-events-caorusel .slick-dots {
  bottom: -3rem;
  width: 100%;
  z-index: 3;
}

#recommended-events-caorusel .slick-dots li.slick-active button:before {
  width: 14px;
  height: 14px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: $orangeColor;
}

#recommended-events-caorusel .slick-dots li button::before {
  border-radius: 59px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  content: "\2022";
  text-align: center;
  opacity: 1;
  background-color: $grayColorInactiveDots;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  transition: all 300ms linear;
}

#recommended-events-caorusel .slick-list {
  height: 320px
}

#recommended-events-caorusel .slick-prev::before {
  content: "\f053";
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  margin-left: -15rem;
  text-rendering: auto;
  line-height: 1;
  color: $orangeColor;
  font-size: 50px;
  width: 100%;
}

#recommended-events-caorusel .slick-next::before {
  content: "\f054";
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-right: -10rem;
  color: $orangeColor;
  font-size: 50px;
  width: 100%;
}

#recommended-events-caorusel button.slick-arrow.slick-prev,
#recommended-events-caorusel button.slick-arrow.slick-next {
  z-index: 0;
}

#recommended-events-caorusel button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  display: none;
}

// recommended-events-caorusel-mobile

#recommended-events-caorusel-mobile .slick-dots {
  bottom: -3rem;
  width: 100%;
  z-index: 3;
}

#recommended-events-caorusel-mobile .slick-list {
  height: 340px
}

#recommended-events-caorusel-mobile .slick-dots li.slick-active button:before {
  width: 14px;
  height: 14px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: $orangeColor;
}

#recommended-events-caorusel-mobile .slick-dots li button::before {
  border-radius: 59px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  content: "\2022";
  text-align: center;
  opacity: 1;
  background-color: $grayColorInactiveDots;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  transition: all 300ms linear;
}

#recommended-events-caorusel-mobile .slick-prev::before {
  content: "\f053";
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  margin-left: -6rem;
  text-rendering: auto;
  line-height: 1;
  color: $orangeColor;
  font-size: 40px;
  width: 100%;
}

#recommended-events-caorusel-mobile .slick-next::before {
  content: "\f054";
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-right: -70px;
  color: $orangeColor;
  font-size: 40px;
  width: 100%;
}

#recommended-events-caorusel-mobile button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  display: none;
}
