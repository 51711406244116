// Colors
$blueColor: #2d7dd2;
$darkBlueColor: #003049;
$dullOrangeColor: #f29e6f;
$grayColor: #8e98a7;
$lightBlueColor: #5c9ead;
$lightGrayColor: #eff1f3;
$orangeColor: #ff5e05;
$orangeColorRgba: rgba(126, 187, 149, 0.7);
$whiteColor: #ffffff;

// Fonts
@font-face {
  font-family: 'Lato-Regular';
  src: url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
}

.ff-LatoRegular {
  font-family: 'Lato-Regular';
}

.ff-LatoBold {
  font-family: 'Lato-Bold';
}

.MuiGrid-root .react-tel-input .form-control:focus {
  border-color: $orangeColor;
  box-shadow:0 0 0 1px $orangeColor;
}

.MuiListItem-root .MuiGrid-root .react-tel-input .form-control:focus {
  border-color: #8e98a7;
  box-shadow: 0 0 0 1px #8e98a7;
}

.login-datepicker .MuiOutlinedInput-notchedOutline {
    border: none;
}

.login-datepicker .MuiInputBase-root{
  font-family: 'Lato-Regular';
  font-size: 14px;
  color: #8E98A7;
}

.login-datepicker .MuiOutlinedInput-input {
    padding: 18.5px 0;
}
