$grayColorInactiveDots: #cdcdcd;
$orangeColor: #ff5e05;

#users-carousel .slick-dots {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 8rem;
  width: 100%;
  z-index: 3;
}

#users-carousel .slick-dots li.slick-active button:before {
  width: 14px;
  height: 14px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  top: 0;
  background-color: $orangeColor;
}

#users-carousel .slick-dots li button::before {
  border-radius: 59px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  content: "\2022";
  text-align: center;
  opacity: 1;
  background-color: $grayColorInactiveDots;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  transition: all 300ms linear;
}

#users-carousel .slick-prev::before {
  content: "\f053";
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  margin-left: -25rem;
  text-rendering: auto;
  line-height: 1;
  color: $orangeColor;
  font-size: 50px;
  width: 100%;
}

#users-carousel .slick-next::before {
  content: "\f054";
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-right: -20rem;
  color: $orangeColor;
  font-size: 50px;
  width: 100%;
}

#users-carousel button.slick-arrow.slick-prev {
  display: none !important;
}

#users-carousel button.slick-arrow.slick-next {
  display: none !important;
}
