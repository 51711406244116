@import "../style.scss";

.itemEvent {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
.m-0 {
  margin: 0 !important;
}
.featuredEvent {
  width: 300px !important;
  height: 400px;
  max-height: 400px;
  margin: 1rem 6px;
  &:hover .boxInfo {
    opacity: 0;
  }
  &:hover .boxInfoText {
    opacity: 0;
  }
  &:hover .buttonOverlay {
    opacity: 1;
  }
  &:hover .boxOverlay {
    opacity: 1;
  }
}
.featuredEventMobile {
  width: 100% !important;
  height: 400px;
  max-height: 400px;
  margin: 1rem 6px;
  &:hover .boxInfo {
    opacity: 0;
  }
  &:hover .boxInfoText {
    opacity: 0;
  }
  &:hover .buttonOverlay {
    opacity: 1;
  }
  &:hover .boxOverlay {
    opacity: 1;
  }
}
.normalEvent {
  width: 233px;
  height: 320px;
  max-height: 320px;
  &:hover .boxInfo {
    opacity: 0;
  }
  &:hover .boxInfoText {
    opacity: 0;
  }
  &:hover .buttonOverlay {
    opacity: 1;
  }
  &:hover .boxOverlay {
    opacity: 1;
  }
}
.boxWrapper {
  bottom: 0;
  color: $whiteColor;
  height: 100%;
  position: absolute;
  transition: 0.5s ease;
  width: 100%;
  &:hover {
    background: $orangeColor;
    opacity: 0.8;
  }
}
.boxInfo {
  border-radius: 0% 100% 0 0;
  bottom: 0;
  color: $whiteColor;
  height: 188px;
  opacity: 0.8;
  position: absolute;
  transition: opacity 0.3s ease;
  width: 100%;
}
.boxFeaturedInfo {
  background: $grayColor;
}
.boxNormalInfo {
  background: $grayColor;
}
.boxOverlay {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  padding: 0 20px;
  transition: 0.5s ease;
}
.boxInfoText {
  bottom: 0;
  color: $whiteColor;
  font-family: "Lato-Regular";
  padding: 50px 10px 0 10px;
  position: absolute;
  width: 100%;
}
.boxButtonBuy {
  padding: 10px 0;
}
.w-100 {
  width: 100% !important;
}
