#landing-carousel .slick-dots {
  display: none;
}

#landing-carousel .slick-prev::before {
  color: #eff1f3;
  font-size: 80px;
}

#landing-carousel .slick-next::before {
  color: #eff1f3;
  font-size: 80px;
}

#landing-carousel button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  width: 8%;
}
